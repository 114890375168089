<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h2>{{ button.label }}</h2>
      </div>

      <div
        v-for="form in fields"
        :key="form.position"
        :class="`col-md-${form.columns}`"
      >
        <!-- Label for fields -->
        <label v-if="form.placeholder !== ''">{{ form.placeholder }}</label>
        <!-- If type ==== text or password -->
        <input
          class="form-group"
          v-if="form.type === 'text' || form.type === 'password'"
          :type="form.type"
          v-model="form.model"
          :placeholder="form.placeholder"
          @blur="
            addDatasend(form.model, form.name, form.required, form.message)
          "
        />

        <input
          class="form-group"
          v-if="form.type === 'number'"
          :type="form.type"
          v-model="form.model"
          :placeholder="form.placeholder"
          @blur="
            addDatasend(form.model, form.name, form.required, form.message)
          "
        />
        <!-- If type ==== email -->
        <input
          class="form-group"
          v-if="form.type === 'email'"
          :type="form.type"
          v-model="form.model"
          :placeholder="form.placeholder"
          @blur="
            addDatasend(form.model, form.name, form.required, form.message)
          "
        />
        <!-- If type ==== conventional Select -->
        <select
          class="form-group"
          v-if="form.type === 'select' && form.multiValues.length > 0"
          v-model="form.model"
          @blur="
            addDatasend(form.model, form.name, form.required, form.message)
          "
        >
          <option
            v-for="(opt, key) in form.multiValues"
            :key="key"
            :value="opt.value"
          >
            {{ opt.label }}
          </option>
        </select>
        <!-- If type ==== textarea -->
        <div v-if="form.type === 'textarea'">
          <textarea
            class="form-group"
            :placeholder="form.placeholder"
            v-model="form.model"
            @blur="
              addDatasend(form.model, form.name, form.required, form.message)
            "
          ></textarea>
        </div>
        <!-- If type === data -->
        <date-picker
          v-if="form.type === 'date' && form.config.type === 'range'"
          v-model="form.model"
          range
          @change="
            addDatasend(form.model, form.name, form.required, form.message)
          "
        ></date-picker>
        <date-picker
          v-if="form.type === 'date' && form.config.type !== 'range'"
          v-model="form.model"
          @change="
            addDatasend(form.model, form.name, form.required, form.message)
          "
        ></date-picker>
        <input
          type="date"
          class="form-group"
          v-if="form.type === 'date_normal'"
          v-model="form.model"
          @change="
            addDatasend(form.model, form.name, form.required, form.message)
          "
        />
        <!-- If type === checkbox -->
        <div class="checkbox row" v-if="form.type === 'checkbox'">
          <div
            v-for="(fields, key) in form.multiValues"
            :key="key"
            :class="`col-md-${fields.columns}`"
          >
            <label>{{ fields.label }}</label>
            <input
              type="checkbox"
              @click="checkBox(key, fields.checked, form.name)"
              :v-model="form.model"
              :checked="fields.checked"
            />
          </div>
        </div>

        <!-- If type === checkbox -->
        <div class="checkbox row" v-if="form.type === 'radio'">
          <div
            v-for="(fields, key) in form.multiValues"
            :key="key"
            :class="`col-md-${fields.columns}`"
          >
            <label class="checkLabel">{{ fields.label }}</label>
            <input
              type="checkbox"
              @click="radioBox(key, fields.checked, form.name)"
              :v-model="form.model"
              :checked="fields.checked"
            />
          </div>
        </div>

        <small v-if="sendForm[form.name].erro">{{
          sendForm[form.name].message
        }}</small>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button
          :class="`col-md-${button.columns}`"
          :style="`width:${button.width}; color:${button.color}; margin:${button.margin}; background-color:${button.background}; padding:${button.padding}`"
          @click="send()"
        >
          {{ button.label }}
        </button>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { createClient } from "../../../services/client.services";
import { createUser } from "../../../services/user.service";

export default {
  props: {
    fields: Array,
    button: Object,
    configs: Object,
    type: String,
  },
  name: "Form",
  components: {
    
  },
  data() {
    return {
      sendForm: {},
      letSend: false,
      
    };
  },
  computed: {},
  created() {
    this.createSendForm();
    
  },
  mounted() {},
  watch: {},
  methods: {
   
    createSendForm() {
      this.fields.forEach((val) => {
        this.sendForm[val.name] = {
          name: null,
          required: val.required,
          message: val.message,
          multiValues: val.multiValues,
          config: val.config,
        };
      });
    },
    addDatasend(data, name, required, message) {
      let error;
      if ((data === "" || data === null) && required === true) {
        error = true;
      } else {
        error = false;
      }
      this.sendForm[name] = {
        erro: error,
        name: data,
        required: required,
        message: message,
      };
      
      this.$forceUpdate();
    },
    checkBox(key, checked, name) {
      this.sendForm[name].name = [];
      this.sendForm[name].multiValues.map((val, index) => {
        if (key === index) {
          if (checked) {
            val.checked = false;
          } else {
            val.checked = true;
          }
        }
      });
     
    },

    radioBox(key, checked, name) {
      this.sendForm[name].name = [];
      this.sendForm[name].multiValues.map((val, index) => {
        if (key === index) {
          if (checked) {
            val.checked = false;
          } else {
            val.checked = true;
          }
        } else {
          val.checked = false;
        }
      });
     
    },
    send() {
      let validate = [];
      Object.keys(this.sendForm).filter((val) => {
        if (
          this.sendForm[val].required === true &&
          (this.sendForm[val].name === null || this.sendForm[val].name === "")
        ) {
          validate.push(this.sendForm[val]);
        }
      });
      if (validate.length > 0) {
        this.$toast.error("Preencha os campos obrigatórios");
      } else {
        if (this.type === "customers") {
          let send = {
            client_name: this.sendForm["name"].name,
            limit_matriculas: this.sendForm["matriculalimit"].name,
            limit_contratos: this.sendForm["contractlimit"].name,
            active: this.sendForm["active"].multiValues[1].checked,
            expiry_date: this.sendForm["expiration"].name,
          };
          createClient(send)
            .then(() => {
              let obj = {};
              this.sendForm = { ...obj };
              this.$emit('recharge','customers')
              this.createSendForm();
              this.$forceUpdate();
              this.$toast.success("Cliente salvo com sucesso");
            })
            .catch((e) => {
              this.$tokentest(e.response.status)
              this.$toast.error("Ocorreu um erro ao salvar o cliente");
            });
        }
        else {
            let send = {
            client_name: this.sendForm["client_name"].name,
            
            password: this.sendForm['password'].name,
            user_type:this.sendForm['user_type'].name,
            username: this.sendForm["username"].name,
          };
          createUser(send)
            .then(() => {
              let obj = {};
              this.sendForm = { ...obj };
              this.$emit('recharge','users')
              this.createSendForm();
              this.$forceUpdate();
              this.$toast.success("Cliente salvo com sucesso");
            })
            .catch((e) => {
              this.$tokentest(e.response.status)
              this.$toast.error("Ocorreu um erro ao salvar o cliente");
            });
        }
      }
    },
  },
};
</script>
<style src="./form.less" lang="less" />
